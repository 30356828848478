export interface DepositRate {
  term: {
    type: 'months' | 'days'; // Same
    value: number | string;
  };
  minimumDeposit: {
    value: number;
  };
  maturity: {
    value: number;
  };
  annually?: {
    value: number;
  };
  semiAnnually?: {
    value: number;
  };
  monthly?: {
    value: number;
  };
}

// Follow the format - MM/DD/YYYY
export const effectiveDate = new Date('09/16/2024');

export const depositRates: DepositRate[] = [
  {
    term: {
      type: 'months',
      value: 12,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 4.18,
    },
    annually: {
      value: 4.18,
    },
    semiAnnually: {
      value: 4.08,
    },
    monthly: {
      value: 3.98,
    },
  },
  {
    term: {
      type: 'months',
      value: 24,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.96,
    },
    annually: {
      value: 3.96,
    },
    semiAnnually: {
      value: 3.86,
    },
    monthly: {
      value: 3.76,
    },
  },
  {
    term: {
      type: 'months',
      value: 36,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.91,
    },
    annually: {
      value: 3.91,
    },
    semiAnnually: {
      value: 3.81,
    },
    monthly: {
      value: 3.71,
    },
  },
  {
    term: {
      type: 'months',
      value: 48,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.83,
    },
    annually: {
      value: 3.83,
    },
    semiAnnually: {
      value: 3.73,
    },
    monthly: {
      value: 3.63,
    },
  },
  {
    term: {
      type: 'months',
      value: 60,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.79,
    },
    annually: {
      value: 3.79,
    },
    semiAnnually: {
      value: 3.69,
    },
    monthly: {
      value: 3.59,
    },
  },
  {
    term: {
      type: 'days',
      value: '30-59',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 0.1,
    },
  },
  {
    term: {
      type: 'days',
      value: '60-89',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 0.15,
    },
  },
  {
    term: {
      type: 'days',
      value: '90-179',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 1.1,
    },
  },
  {
    term: {
      type: 'days',
      value: '180-269',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 2.01,
    },
  },
  {
    term: {
      type: 'days',
      value: '270-364',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 2.36,
    },
  },
];
